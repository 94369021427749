import { createRef } from 'react'
import IconButton from '@mui/material/IconButton'
import { tableIcons } from '../helpers/constants'

import { ConfigurationProvider } from './contexts/ConfigurationContext'
import { NodeTypesProvider } from './contexts/NodeTypes'
import { FieldValidationProvider } from './contexts/FieldValidation'
import { EditingModeProvider } from './contexts/EditingMode'
import { SystemDefaultsProvider } from './contexts/SystemDefaults'
import { UserPreferencesProvider } from './contexts/UserPreferences'
import { InstanceProvider } from './contexts/Instance/Instance'
import { ThemeProvider } from './contexts/ThemeContext/'
import makeStyles from '@mui/styles/makeStyles'
import { SnackbarProvider } from 'notistack'
import { colors } from 'assets/styles/main'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import WarningIcon from '@mui/icons-material/Warning'
import InfoIcon from '@mui/icons-material/Info'
import { StyledEngineProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

const useStyles = makeStyles({
   success: {
      backgroundColor: 'rgb(237, 247, 237) !important',
      color: 'rgb(30, 70, 32) !important',
   },

   error: { backgroundColor: 'rgb(253, 236, 234) !important', color: 'rgb(97, 26, 21) !important' },
   warning: {
      backgroundColor: 'rgb(255, 244, 229) !important',
      color: 'rgb(102, 60, 0) !important',
   },
   info: { backgroundColor: 'rgb(232, 244, 253) !important', color: 'rgb(13, 60, 97) !important' },
})

const StoreProvider = ({ children }) => {
   const classes = useStyles()
   const notistackRef = createRef()
   const onClickDismiss = key => () => {
      notistackRef.current.closeSnackbar(key)
   }

   return (
      <StyledEngineProvider injectFirst>
         <ThemeProvider>
            <SnackbarProvider
               iconVariant={{
                  success: (
                     <CheckCircleOutlineIcon
                        style={{ color: colors.successButton, marginRight: 10 }}
                     />
                  ),
                  error: <ErrorOutlineIcon style={{ color: '#f44336', marginRight: 10 }} />,
                  warning: <WarningIcon style={{ color: '#ff9800', marginRight: 10 }} />,
                  info: <InfoIcon style={{ color: '#2196f3', marginRight: 10 }} />,
               }}
               ref={notistackRef}
               preventDuplicate
               anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
               autoHideDuration={6000}
               action={key => (
                  <IconButton size="small" onClick={onClickDismiss(key)}>
                     {tableIcons.Clear}
                  </IconButton>
               )}
               classes={{
                  variantSuccess: classes.success,
                  variantError: classes.error,
                  variantWarning: classes.warning,
                  variantInfo: classes.info,
               }}
            >
               <UserPreferencesProvider>
                  <ConfigurationProvider>
                     <SystemDefaultsProvider>
                        <NodeTypesProvider>
                           <InstanceProvider>
                              <FieldValidationProvider>
                                 <EditingModeProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                       {children}
                                    </LocalizationProvider>
                                 </EditingModeProvider>
                              </FieldValidationProvider>
                           </InstanceProvider>
                        </NodeTypesProvider>
                     </SystemDefaultsProvider>
                  </ConfigurationProvider>
               </UserPreferencesProvider>
            </SnackbarProvider>
         </ThemeProvider>
      </StyledEngineProvider>
   )
}

export default StoreProvider
