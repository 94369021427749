import keycloak from 'keycloak'
import setAuthToken from 'helpers/setAuthToken'

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = onAuthenticatedCallback => {
   keycloak
      .init({
         onLoad: 'check-sso',
         silentCheckSsoRedirectUri: window.location.origin + '/kc/silent-check-sso.html',
         pkceMethod: 'S256',
      })
      .then(authenticated => {
         if (!authenticated) {
            doLogin()
         }
         onAuthenticatedCallback()
         localStorage.setItem('token', keycloak.token)
         localStorage.setItem('refreshToken', keycloak.refreshToken)
      })
      .catch(err => {
         console.error(err)
      })
}

const doLogin = keycloak.login

const doLogout = () => {
   keycloak.logout()
   localStorage.removeItem('token')
   localStorage.removeItem('refreshToken')
}

const getToken = () => keycloak.token

const isLoggedIn = () => !!keycloak.token

const updateToken = () => {
   keycloak
      .updateToken(300)
      .then(refreshed => {
         if (refreshed) {
            localStorage.setItem('token', keycloak.token)
            localStorage.setItem('refreshToken', keycloak.refreshToken)
            setAuthToken(keycloak.token)
         } else {
            console.warn(
               'Token not refreshed, valid for ' +
                  Math.round(
                     keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000
                  ) +
                  ' seconds'
            )
         }
      })
      .catch(error => {
         console.error('Failed to refresh token', error)
      })
}

const getUsername = () => keycloak.tokenParsed?.preferred_username

const hasRole = roles => roles.some(role => keycloak.hasRealmRole(role))

const UserService = {
   initKeycloak,
   doLogin,
   doLogout,
   isLoggedIn,
   getToken,
   updateToken,
   getUsername,
   hasRole,
}

export default UserService
