const systemDefaultsReducer = (state, action) => {
   const { type, payload } = action

   switch (type) {
      case 'UNITS_OF_MEASUREMENT_LOADED':
         return {
            errorSystemDefaults: null,
            isErrorSystemDefaults: false,
            isLoadingSystemDefaults: false,
            unitsOfMeasurement: payload.unitsArray,
            unitsOfMeasurementObject: payload.unitsObject,
         }
      case 'UNIT_OF_MEASUREMENT_CREATED':
         return {
            errorSystemDefaults: null,
            isErrorSystemDefaults: false,
            isLoadingSystemDefaults: false,
            unitsOfMeasurement: [...state.unitsOfMeasurement, payload],
            unitsOfMeasurementObject: { ...state.unitsOfMeasurementObject, [payload.id]: payload },
         }
      case 'UNIT_OF_MEASUREMENT_DELETED':
         delete state.unitsOfMeasurementObject[payload]
         return {
            ...state,
            errorSystemDefaults: null,
            isErrorSystemDefaults: false,
            isLoadingSystemDefaults: false,
            unitsOfMeasurement: state.unitsOfMeasurement.filter(item => item.id !== payload),
         }
      case 'UNIT_OF_MEASUREMENT_EDITED':
         return {
            ...state,
            errorSystemDefaults: null,
            isErrorSystemDefaults: false,
            isLoadingSystemDefaults: false,
            unitsOfMeasurement: state.unitsOfMeasurement.map(x => {
               const item = payload.find(({ id }) => id === x.id)
               return item ? item : x
            }),
            unitsOfMeasurementObject: {
               ...state.unitsOfMeasurementObject,
               [payload[0].id]: payload[0],
            },
         }
      case 'ERROR_LOADING_UOMS':
         return {
            ...state,
            errorSystemDefaults: payload,
            isErrorSystemDefaults: true,
            isLoadingSystemDefaults: false,
         }
      default:
         return state
   }
}

export default systemDefaultsReducer
