import { memo, useEffect, useState } from 'react'
import Box from '@mui/material/Box'

import { Header, Sidebar, Content } from './components'
import { CircularProgress } from '@mui/material'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { PageURLs } from 'Routes'

const Main = ({ isSuspense = false }) => {
   const [isMobileNavOpen, setMobileNavOpen] = useState(false)
   const { pathname } = useLocation()
   const navigate = useNavigate()

   useEffect(() => {
      if (pathname === '/') {
         navigate(PageURLs.Dashboard)
      }
      // eslint-disable-next-line
   }, [pathname])

   return (
      <>
         <Header onMobileNavOpen={() => setMobileNavOpen(true)} />
         <Box display="flex">
            <Sidebar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
            <Content>{isSuspense ? <CircularProgress /> : <Outlet />}</Content>
         </Box>
      </>
   )
}

export default memo(Main)
