import { useState } from 'react'
import {
   Menu,
   MenuItem,
   Box,
   useTheme,
   IconButton,
   AppBar,
   Toolbar,
   CardHeader,
   Avatar,
   Typography,
   useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Link } from 'react-router-dom'
import { Language } from './components'
import Logo from 'views/components/Logo'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useUserPreferences } from 'core'
import UserService from 'services/UserService'

const useStyles = makeStyles(theme => ({
   root: {
      height: theme.sizing.header.height,
   },
   toolbar: {
      height: '100%',
      justifyContent: 'space-between',
   },
   menu: {
      marginRight: theme.spacing(1),
   },
   cardHeader: {
      '& .MuiCardHeader-action': {
         margin: 0,
         marginLeft: theme.spacing(2),
      },
   },
   logo: {
      display: 'flex',
      alignItems: 'center',
   },
}))

const Header = ({ onMobileNavOpen }) => {
   const theme = useTheme()
   const classes = useStyles()
   const [anchorEl, setAnchorEl] = useState(null)
   const { toggleSidebarCollapse } = useUserPreferences()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

   const handleClick = event => {
      setAnchorEl(event.currentTarget)
   }

   const handleClose = () => {
      setAnchorEl(null)
   }

   return (
      <AppBar position="fixed" className={classes.root}>
         <Toolbar className={classes.toolbar}>
            <Box display="flex" alignItems="center">
               <IconButton
                  size="small"
                  className={classes.menu}
                  onClick={isMobile ? onMobileNavOpen : toggleSidebarCollapse}
               >
                  <MenuOpenIcon sx={{ color: 'primary.contrastText' }} />
               </IconButton>
               <Link to="/" className={classes.logo}>
                  <Logo />
               </Link>
            </Box>
            <Box display="flex" alignItems="center">
               <Language />
               <CardHeader
                  className={classes.cardHeader}
                  avatar={
                     <Avatar aria-label="user avatar" className={classes.avatar}>
                        {UserService.keycloak?.tokenParsed?.name[0]?.toUpperCase()}
                     </Avatar>
                  }
                  action={
                     <IconButton aria-label="settings" onClick={handleClick} size="large">
                        <MoreVertIcon sx={{ color: 'primary.contrastText' }} />
                     </IconButton>
                  }
                  title={
                     <Typography variant="subtitle2">
                        {UserService.keycloak?.tokenParsed?.name?.toUpperCase()}
                     </Typography>
                  }
                  subheader={<Typography variant="body2">{UserService.getUsername()}</Typography>}
               />

               <Menu
                  id="user"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  style={{ zIndex: 2004 }}
               >
                  <MenuItem onClick={UserService.doLogout}>Logout</MenuItem>
               </Menu>
            </Box>
         </Toolbar>
      </AppBar>
   )
}

export default Header
