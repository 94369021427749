import { capitalize } from '@mui/material/utils'
import {
   LOCALIZED_ATTRIBUTE_TYPE_ID,
   RANGE_ATTRIBUTES_TYPE_ID,
   RANGE_ATTRIBUTE_DELIMITER,
   RELATIONS_PER_PAGE,
} from 'helpers/constants'

export const getRelationsRequestData = (
   relationBlueprint,
   startPageFrom,
   instanceId,
   isAsc,
   attributeIds
) => {
   return [
      {
         asc: isAsc,
         isLeft: relationBlueprint.isLeft,
         attributeIds: attributeIds,
         from: startPageFrom || 0,
         pageSize: RELATIONS_PER_PAGE,
         q: relationBlueprint.q,
         rbId: relationBlueprint.id,
         rootId: instanceId,
      },
   ]
}

export const getLabelAttributes = (configuration, currentType) => {
   const labelAttributes = configuration.conf.label?.type[currentType]?.labelAttributes
   return labelAttributes ? labelAttributes : []
}

export const getLabelDelimiter = labelAttribute => {
   return `!{${labelAttribute}}!?`
}

export const displayValue = (ruleType, ruleValue, uoms, configuration) => {
   switch (ruleType) {
      case 'UNIT_OF_MEASUREMENT':
         return `${uoms[ruleValue]?.name} (${uoms[ruleValue]?.symbol})`
      case 'DATE':
         return new Intl.DateTimeFormat(
            configuration.conf.localization.defaultLocale.replaceAll('_', '-'),
            {
               dateStyle: 'short',
               timeStyle: 'short',
            }
         ).format(new Date(+ruleValue))
      default:
         return ruleValue
   }
}

export const getLabel = (relationBlueprint, relationInstance, configuration, nodeTypes, uoms) => {
   if (typeof relationInstance === 'string') return

   const nodeType = nodeTypes.find(nodeType => nodeType.id === relationBlueprint.nt).n.toLowerCase()

   const labelConfiguration = getLabelAttributes(
      configuration,
      nodeTypes.find(nodeType => nodeType.id === relationBlueprint.nt).n.toLowerCase()
   )

   if (!labelConfiguration) {
      return `${capitalize(relationBlueprint.q)} (${relationInstance.id})`
   }

   const labelAttributes = getLabelAttributes(
      configuration,
      nodeTypes.find(nodeType => nodeType.id === relationBlueprint.nt).n.toLowerCase()
   )

   let label = configuration.conf.label.type[nodeType]?.label

   if (!label) {
      return `${capitalize(relationBlueprint.q)} (${relationInstance.id})`
   }

   for (let i = 0; i < labelAttributes.length; i++) {
      const rule = relationInstance?.rules?.find(rule =>
         rule.rk.nt === LOCALIZED_ATTRIBUTE_TYPE_ID
            ? rule.rk.lc === configuration.conf.localization.defaultLocale &&
              rule.rk.ni === labelAttributes[i]
            : rule.rk.ni === labelAttributes[i]
      )

      const ruleType = rule?.rk?.rt
      const ruleValue = rule?.rv?.val

      if (ruleValue) {
         if (rule.rk.nt === RANGE_ATTRIBUTES_TYPE_ID) {
            const rangeValues = ruleValue.split(RANGE_ATTRIBUTE_DELIMITER)
            const firstValue = displayValue(ruleType, rangeValues[0], uoms, configuration)
            const secondValue = displayValue(ruleType, rangeValues[1], uoms, configuration)

            label = label.replaceAll(
               getLabelDelimiter(labelAttributes[i]),
               `${firstValue} - ${secondValue}`
            )
         } else {
            label = label.replaceAll(
               getLabelDelimiter(labelAttributes[i]),
               displayValue(ruleType, ruleValue, uoms, configuration)
            )
         }
      } else {
         label = label.replaceAll(getLabelDelimiter(labelAttributes[i]), '')
      }
   }

   return capitalize(label)
}

export const constructRelation = ({
   relationBlueprint,
   relation,
   configuration,
   nodeTypes,
   instanceId,
   uoms,
}) => {
   return {
      displayValue: getLabel(relationBlueprint, relation, configuration, nodeTypes, uoms),
      payload: {
         id: relationBlueprint.id,
         isLeft: relationBlueprint.isLeft,
         nodeId: relation.id,
         instanceId,
      },
      relation,
      relationBlueprint,
   }
}

export const constructRelationBlueprintState = relationBlueprint => {
   return `id${relationBlueprint.id}nt${relationBlueprint.nt}isLeft${relationBlueprint.isLeft}q${relationBlueprint.q}`
}
