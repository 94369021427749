import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { LANGUAGE } from 'config/constants/userPreferences'

import bg from './files/bg.json'
import en from './files/en.json'
import dashboardBG from './files/dashboardBG.json'
import dashboardEN from './files/dashboardEN.json'
import treeViewBG from './files/treeViewBG.json'
import treeViewEN from './files/treeViewEN.json'
import enrichmentBG from './files/enrichmentBG.json'
import enrichmentEN from './files/enrichmentEN.json'
import configurationBG from './files/configurationBG.json'
import configurationEN from './files/configurationEN.json'
import uomsEN from './files/uomsEN.json'
import uomsBG from './files/uomsBG.json'
import cronjobsBG from './files/cronjobsBG.json'
import cronjobsEN from './files/cronjobsEN.json'
import exportEN from './files/exportEN.json'
import exportBG from './files/exportBG.json'
import importEN from './files/importEN.json'
import importBG from './files/importBG.json'
import publishingEN from './files/publishingEN.json'
import publishingBG from './files/publishingBG.json'
import errorsEN from './files/errorsEN.json'
import errorsBG from './files/errorsBG.json'

const resources = {
   'en-UK': {
      translation: en,
      dashboard: dashboardEN,
      treeView: treeViewEN,
      enrichment: enrichmentEN,
      uoms: uomsEN,
      cronjobs: cronjobsEN,
      configuration: configurationEN,
      import: importEN,
      export: exportEN,
      publishing: publishingEN,
      errors: errorsEN,
   },
   'bg-BG': {
      translation: bg,
      dashboard: dashboardBG,
      treeView: treeViewBG,
      enrichment: enrichmentBG,
      uoms: uomsBG,
      cronjobs: cronjobsBG,
      configuration: configurationBG,
      import: importBG,
      export: exportBG,
      publishing: publishingBG,
      errors: errorsBG,
   },
}

i18n.use(initReactI18next).init({
   resources,
   lng: localStorage.getItem('language') || LANGUAGE,
   keySeparator: false,
   interpolation: {
      escapeValue: false,
   },
})

export default i18n
