/*
 * Server URLs and settings
 * Don't put a slash at the end of the main URLs
 */

// TODO: refactor logic to avoid complex ternary operators

// export const isMultiTenancy = process.env.CLOUD_ENV && window.env.MULTI_TENANCY_CONTEXT === undefined ? false :
//     process.env.CLOUD_ENV ? JSON.parse(window.env.MULTI_TENANCY_CONTEXT) :
//     process.env.REACT_APP_IS_MULTI_TENANCY === undefined
//       ? false
//       : JSON.parse(process.env.REACT_APP_IS_MULTI_TENANCY)
// process.env.REACT_APP_IS_MULTI_TENANCY doesnt appear to be set/used anywhere

// suggesting refactor to:
export const isMultiTenancy = window.env?.MULTI_TENANCY_CONTEXT || false

export const apiPath =
   process.env.CLOUD_ENV && window.env.MORPH_API_PATH !== ''
      ? window.env.MORPH_API_PATH
      : process.env.REACT_APP_API_PATH || '/morph/api'
export const multiTenancySystemId =
   window.env.CID !== 'ENV_CID_PLACEHOLDER' ? window.env.CID : window.location.host.split('.')[0]

export const devServerUrl = 'http://localhost:8080'
export const devKeycloakUrl = 'http://keycloak.novarto.com/auth/'

/* If the server url origin is the same as the UI, use only apiPath
 * ex: if front-end url is https://pim.com
 * and back-end is at https://pim.com/api
 * set only apiPath (without serverUrl) to "/api"
 */
export const serverUrl =
   window.env.CLOUD_ENV && window.env.MORPH_URL !== ''
      ? window.env.MORPH_URL
      : process.env.REACT_APP_SERVER_URL ||
        (isMultiTenancy
           ? `${window.location.origin}${apiPath}`
           : process.env.NODE_ENV === 'development'
           ? devServerUrl
           : `${window.location.origin}${apiPath}`)

// Keycloak settings
export const keycloakApiPath = '/'
export const keycloakRealm =
   isMultiTenancy && multiTenancySystemId
      ? `cid-${multiTenancySystemId}`
      : process.env.REACT_APP_KEYCLOAK_REALM || 'pim'
export const keycloakClientId = process.env.REACT_APP_KEYCLOAK_CLIENTID || 'frontend'
export const keycloakUrl =
   window.env.CLOUD_ENV && window.env.KEYCLOAK_URL !== ''
      ? window.env.KEYCLOAK_URL
      : process.env.REACT_APP_KEYCLOAK_URL ||
        (isMultiTenancy
           ? `${window.location.origin}${keycloakApiPath}`
           : process.env.NODE_ENV === 'development'
           ? devKeycloakUrl
           : `${window.location.origin}${keycloakApiPath}`)
export const keycloakSSOClientScope = window.env.CLOUD_ENV ? window.env.KEYCLOAK_SSO_CLIENT_SCOPE : 'dibase-sso'

// Other
export const CONFIGURATION_PROFILE_NAME = 'morph-ui'
export const APP_CONTEXT =
    window.env.CLOUD_ENV
        ? window.env.APP_CONTEXT
        : process.env.REACT_APP_APP_CONTEXT === undefined
            ? true
            : process.env.REACT_APP_APP_CONTEXT
export const routerBasename = window.env.CLOUD_ENV
   ? window.env.ROUTER_BASENAME
   : process.env.REACT_APP_ROUTER_BASENAME || '/'
