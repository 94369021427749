import { createContext, useContext, useEffect, useReducer } from 'react'
import axios from 'axios'
import reducer from './reducer'
import { APP_CONTEXT, serverUrl } from 'helpers/backendDependencies'
import setAuthToken from 'helpers/setAuthToken'
import useError from 'helpers/useError'

const initialState = {
   error: null,
   isError: false,
   isLoading: true,
   nodeTypes: [],
   tree: {},
   instances: [],
}

const NodeTypesContext = createContext(initialState)

const NodeTypesProvider = ({ children }) => {
   const [state, dispatch] = useReducer(reducer, initialState)
   const { setError } = useError()

   useEffect(() => {
      if (localStorage.token) {
         setAuthToken(localStorage.token)
      }

      loadNodeTypes()
      // eslint-disable-next-line
   }, [])

   const loadNodeTypes = async () => {
      try {
         const { data: nodeTypesData } = await axios(`${serverUrl}/nodeTypes`)
         const { data: productTreeId } = APP_CONTEXT === 'pim'
            ? await axios(
                 `${serverUrl}/blueprints/relations/${
                    nodeTypesData.find(nodeType => nodeType.n.toLowerCase() === 'category')?.id
                 }?qualifier=products`
              )
            : { data: null }
         const { data: categoryTreeId } = APP_CONTEXT === 'pim'
            ? await axios(
                 `${serverUrl}/blueprints/relations/${
                    nodeTypesData.find(nodeType => nodeType.n.toLowerCase() === 'category')?.id
                 }?qualifier=superCategory`
              )
            : { data: null }
         const { data: catalogTreeId } = APP_CONTEXT === 'pim'
            ? await axios(
                 `${serverUrl}/blueprints/relations/${
                    nodeTypesData.find(nodeType => nodeType.n.toLowerCase() === 'category')?.id
                 }?qualifier=catalog`
              )
            : { data: null }
         dispatch({
            type: 'NODE_TYPES_LOADED',
            payload: {
               nodeTypes: nodeTypesData,
               tree: { product: productTreeId, category: categoryTreeId, catalog: catalogTreeId },
            },
         })
      } catch (error) {
         dispatch({
            type: 'ERROR_LOADING',
            payload: error.message,
         })
         setError(error)
      }
   }

   return (
      <NodeTypesContext.Provider
         value={{
            error: state.error,
            isError: state.isError,
            isNodeTypesLoading: state.isLoading,
            nodeTypes: state.nodeTypes,
            tree: state.tree,
            instances: state.instances,
            loadNodeTypes,
         }}
         displayName="Node Types"
      >
         {children}
      </NodeTypesContext.Provider>
   )
}

const useNodeTypes = () => {
   const context = useContext(NodeTypesContext)
   if (context === undefined) {
      throw new Error('useNodeTypes can only be used inside NodeTypesProvider')
   }
   return context
}

export { NodeTypesProvider, useNodeTypes }
