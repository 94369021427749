import { keycloakClientId, keycloakRealm, keycloakUrl, keycloakSSOClientScope } from 'helpers/backendDependencies'
import Keycloak from 'keycloak-js'

const keycloak = new Keycloak({
   url: keycloakUrl,
   realm: keycloakRealm,
   clientId: keycloakClientId,
   clientScopes: [ keycloakSSOClientScope ],
   onLoad: 'login-required',
   'ssl-required': 'external',
   'enable-cors': true,
   checkLoginIframe: false,
})

export default keycloak
