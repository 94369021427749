/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
   loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '250px',
      flexDirection: 'column',
   },
})

const Loading = () => {
   const classes = useStyles()
   const [timeLeft, setTimeLeft] = useState(0) // in seconds
   const { t } = useTranslation(['translation'])
   useEffect(() => {
      // exit early when we reach 0
      if (!timeLeft) return

      // save intervalId to clear the interval when the
      // component re-renders
      const intervalId = setInterval(() => {
         setTimeLeft(timeLeft - 1)
      }, 1000)

      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId)
      // add timeLeft as a dependency to re-rerun the effect
      // when we update it
   }, [timeLeft])

   if (!timeLeft) {
      return (
         <Box className={classes.loading}>
            <CircularProgress />
            <Box>{t('JustASecond')}</Box>
         </Box>
      )
   }

   return null
}

export default Loading
