import { useContext, createContext, useReducer, useMemo } from 'react'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { theme as defaultTheme } from 'theme'

const themeReducer = (state, action) => {
   const { type, payload } = action

   switch (type) {
      case 'CHANGE_PRIMARY_COLOR':
         localStorage.setItem('defaultPrimaryColor', payload ?? '#007dff')
         return {
            ...state,
            primaryColor: payload ?? '#007dff',
         }
      default:
         throw new Error()
   }
}

const initialState = {
   primaryColor: localStorage.getItem('defaultPrimaryColor') ?? defaultTheme.palette.primary.main,
}

const ThemeContext = createContext(null)

export const ThemeProvider = ({ children }) => {
   const [state, dispatch] = useReducer(themeReducer, initialState)

   const memoizedTheme = useMemo(() => {
      return createTheme({
         ...defaultTheme,
         palette: {
            ...defaultTheme.palette,
            primary: {
               main: state.primaryColor,
            },
         },
      })
   }, [state])

   const changePrimaryColor = color => {
      dispatch({
         type: 'CHANGE_PRIMARY_COLOR',
         payload: color,
      })
   }

   return (
      <MuiThemeProvider theme={memoizedTheme}>
         <ThemeContext.Provider
            value={{ primaryColor: state.primaryColor, changePrimaryColor }}
            displayName="Edit Theme"
         >
            {children}
         </ThemeContext.Provider>
      </MuiThemeProvider>
   )
}

export const useThemeEdit = () => {
   const context = useContext(ThemeContext)

   if (context === undefined) {
      throw new Error('useThemeEdit can only be used inside ThemeContext')
   }

   return context
}
