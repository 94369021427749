import { createContext, useContext, useReducer } from 'react'
import reducer from './reducer'

const initialState = {
   editedFields: [],
}

const EditingModeContext = createContext(initialState)

const EditingModeProvider = ({ children }) => {
   const [state, dispatch] = useReducer(reducer, initialState)

   const addField = field => {
      dispatch({
         type: 'ADD_FIELD',
         payload: field,
      })
   }

   const removeField = field => {
      dispatch({
         type: 'REMOVE_FIELD',
         payload: field,
      })
   }

   const clearContext = () => {
      dispatch({
         type: 'CLEAR_CONTEXT',
         payload: [],
      })
   }

   return (
      <EditingModeContext.Provider
         value={{
            editedFields: state.editedFields,
            addField,
            removeField,
            clearContext,
         }}
         displayName="Editing Mode"
      >
         {children}
      </EditingModeContext.Provider>
   )
}

const useEditingMode = () => {
   const context = useContext(EditingModeContext)
   if (context === undefined) {
      throw new Error('useEditingMode can only be used inside EditingModeProvider')
   }
   return context
}

export { EditingModeProvider, useEditingMode }
