import { TAB_IDS } from 'helpers/constants'
import { constructRelationBlueprintState } from 'views/pages/NewInstance/components/TabPanel/components/RelationCard/functions'

const instanceReducer = (state, action) => {
   const { type, payload } = action

   switch (type) {
      case 'SET_INSTANCE':
         return {
            ...state,
            instanceId: payload.instanceId,
            bom: payload.bom || null,
            relationBlueprints: payload.relationBlueprints || [],
            relationBlueprintsStates: payload.relationBlueprintsStates || [],
            relationsForRemove: payload.relationsForRemove || [],
            relationsForRemoveRaw: payload.relationsForRemoveRaw || [],
            newRelations: payload.newRelations || [],
            newRelationsRaw: payload.newRelationsRaw || [],
            rules: payload.rules || [],
            modifiers: payload.modifiers || [],
            attributeGroups: payload.attributeGroups || [],
            enums: payload.enums || {},
            classificationModes: payload.classificationModes || [],
            publishingData: payload.publishingData || {},
         }
      case 'SET_FORMIK_INITIAL_VALUES':
         return {
            ...state,
            initialValues: payload,
            isInstanceLoading: false,
         }
      case 'SET_INSTANCE_LOADING':
         return {
            ...state,
            isInstanceLoading: true,
         }
      case 'SET_CLASSIFICATION_TREES':
         return {
            ...state,
            classificationTrees: payload || [],
         }
      case 'SET_CLASSIFICATION_LABELS':
         return {
            ...state,
            classificationLabels: payload || [],
         }
      case 'UPDATE_PUBLISHING_DATA':
         return {
            ...state,
            publishingData: payload,
         }
      case 'CHANGE_TAB':
         return {
            ...state,
            tabValue: payload,
         }
      case 'ADD_TO_RELATIONS_FOR_REMOVE':
         return {
            ...state,
            relationsForRemove: [...state.relationsForRemove, payload.payload],
            relationsForRemoveRaw: [...state.relationsForRemoveRaw, payload],
         }
      case 'ADD_ARRAY_TO_NEW_RELATIONS':
         return {
            ...state,
            newRelations: payload.map(newRelation => newRelation.payload),
            newRelationsRaw: payload,
         }
      case 'ADD_TO_NEW_RELATIONS':
         return {
            ...state,
            newRelations: [...state.newRelations, payload.payload],
            newRelationsRaw: [...state.newRelationsRaw, payload],
         }
      case 'RESET_INSTANCE':
         return {
            instanceId: null,
            bom: null,
            newRelations: [],
            relationBlueprints: [],
            relationsForRemove: [],
            rules: [],
            modifiers: [],
            attributeGroups: [],
            classificationTrees: [],
            classificationLabels: [],
            classificationModes: [],
            publishingData: {},
            initialValues: {},
            tabValue: { id: TAB_IDS.rootTab, value: 0 },
            isInstanceLoading: true,
            relationsForRemoveRaw: [],
            newRelationsRaw: [],
         }
      case 'REMOVE_FROM_NEW_RELATIONS':
         return {
            ...state,
            newRelations: state.newRelations.filter(
               newRelation => newRelation.nodeId !== payload.payload.nodeId
            ),
            newRelationsRaw: state.newRelationsRaw.filter(
               newRelation => newRelation.payload.nodeId !== payload.payload.nodeId
            ),
         }
      case 'REMOVE_FROM_RELATIONS_FOR_REMOVE':
         return {
            ...state,
            relationsForRemove: state.relationsForRemove.filter(
               relationForRemove => relationForRemove.nodeId !== payload.payload.nodeId
            ),
            relationsForRemoveRaw: state.relationsForRemoveRaw.filter(
               relationForRemove => relationForRemove.payload.nodeId !== payload.payload.nodeId
            ),
         }
      case 'UPDATE_RELATION_BLUEPRINT_STATE':
         return {
            ...state,
            relationBlueprintsStates: state.relationBlueprintsStates.map(state =>
               state.id === payload.id
                  ? {
                       ...state,
                       shouldGetInitialRelations: false,
                       initialRelations: payload.initialRelations,
                    }
                  : state
            ),
         }
      case 'CREATE_RELATION_BLUEPRINT_STATE_FROM_RELATION_BLUEPRINTS':
         return {
            ...state,
            relationBlueprintsStates: payload.map(relationBlueprint => ({
               id: constructRelationBlueprintState(relationBlueprint),
               shouldGetInitialRelations: true,
               initialRelations: [],
            })),
         }
      case 'EDIT_INITIAL_RELATIONS_IN_RELATION_BLUEPRINT_STATE':
         return {
            ...state,
            relationBlueprintsStates: state.relationBlueprintsStates.map(state =>
               state.id === payload.id
                  ? {
                       ...state,
                       initialRelations: payload.newState,
                    }
                  : state
            ),
         }
      default:
         return state
   }
}

export default instanceReducer
