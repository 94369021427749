const fieldValidationReducer = (state, action) => {
   const { type, payload } = action

   switch (type) {
      case 'ADD_INVALID_FIELD':
         return {
            invalidFields: [...state.invalidFields, payload],
         }
      case 'REMOVE_INVALID_FIELD':
         return {
            invalidFields: state.invalidFields.filter(field => field.name !== payload.name),
         }
      case 'CLEAR_CONTEXT':
         return {
            invalidFields: payload,
         }
      default:
         return state
   }
}

export default fieldValidationReducer
