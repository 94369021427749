import { memo } from 'react'
import { Box, Drawer, Hidden, List, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useUserPreferences } from 'core/contexts/UserPreferences'
import { pimLinks, sidebarLinks, configuratorLinks } from 'Routes'
import { NavItem } from './components'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { APP_CONTEXT } from 'helpers/backendDependencies'

const useStyles = makeStyles(theme => ({
   mobileDrawer: {
      width: theme.sizing.sidebar.width,
   },
   desktopDrawer: {
      width: ({ sidebarCollapsedState }) =>
         sidebarCollapsedState ? theme.sizing.sidebar.collapsedWidth : theme.sizing.sidebar.width,
      height: '100%',
      marginTop: theme.sizing.header.height,
      paddingBottom: theme.sizing.header.height,
      transition: 'width 0.3s',
      overflow: 'visible',
   },
   avatar: {
      cursor: 'pointer',
      width: 64,
      height: 64,
   },
   title: {
      padding: theme.spacing(0, 2.5, 0, 5),
      margin: theme.spacing(3, 0, 1.25),
      fontWeight: theme.typography.fontWeightMedium,
   },
}))

const Sidebar = ({ onMobileClose, openMobile }) => {
   const { sidebarCollapsedState } = useUserPreferences()
   const classes = useStyles({ sidebarCollapsedState })
   const theme = useTheme()

   const insert = (initialArray, index, newItems) => [
      ...initialArray.slice(0, index),
      ...newItems,
      ...initialArray.slice(index),
   ]

   const links =
      APP_CONTEXT === 'pim'
          ? insert(sidebarLinks, 2, pimLinks)
          : APP_CONTEXT === 'configo'
              ? configuratorLinks
              : sidebarLinks

   const content = (
      <Box display="flex" flexDirection="column">
         <List>
            {links.map((link, index) => (
               <NavItem
                  href={link.to}
                  key={index}
                  title={link.name}
                  icon={link.icon}
                  children={link.links}
                  exact={link.exact}
                  sidebarCollapsedState={sidebarCollapsedState}
               />
            ))}
         </List>
      </Box>
   )

   return (
      <>
         <Hidden lgUp>
            <Drawer
               anchor="left"
               classes={{ paper: classes.mobileDrawer }}
               onClose={onMobileClose}
               open={openMobile}
               variant="temporary"
            >
               {content}
            </Drawer>
         </Hidden>
         <Hidden lgDown>
            <Drawer
               anchor="left"
               classes={{ paper: classes.desktopDrawer }}
               open
               variant="persistent"
               style={{ zIndex: theme.zIndex.sideBar }}
            >
               <PerfectScrollbar>{content}</PerfectScrollbar>
            </Drawer>
         </Hidden>
      </>
   )
}

export default memo(Sidebar)
