const editingModeReducer = (state, action) => {
   const { type, payload } = action

   switch (type) {
      case 'ADD_FIELD':
         return {
            editedFields: [...state.editedFields, payload],
         }
      case 'REMOVE_FIELD':
         return {
            editedFields: state.editedFields.filter(fieldId => fieldId !== payload),
         }
      case 'CLEAR_CONTEXT':
         return {
            editedFields: payload,
         }
      default:
         return state
   }
}

export default editingModeReducer
