export const sizes = {
   header: {
      height: 80,
   },
   logo: {
      width: 105,
      height: 20,
   },
   sidebar: {
      width: 240,
      collapsedWidth: 80,
      link: 41,
      icon: 20,
   },
   pageLayout: {
      height: 200,
      paddingBottom: 220,
      width: 1450,
   },
}

export const colors = {
   primary: '#0061f2',
   success: '#4caf50',
   warning: '#f4a100',
   danger: '#e81500',
   info: '#00cfd5',
   background: '#ffffff',
   backgroundSecondary: '#f8f8f9',
   discrete: 'rgba(0, 0, 0, 0.5)',
   successButton: '#4caf50',
   sidebar: {
      link: '#1f2d41',
      linkHover: '#0061f2',
      active: '#0061f2',
      icon: '#a2acba',
      subLinkBorder: '#d7dce3',
   },
   header: {
      text: 'rgba(0, 0, 0, 0.5)',
   },
   pageLayout: {
      gradient: 'linear-gradient(135deg, #0061f2 0%, rgba(105, 0, 199, 0.8) 100%)',
   },
}
