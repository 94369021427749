const nodeTypesReducer = (state, action) => {
   const { type, payload } = action

   switch (type) {
      case 'NODE_TYPES_LOADED':
         return {
            error: null,
            isError: false,
            isLoading: false,
            nodeTypes: payload.nodeTypes,
            instances: payload.nodeTypes.filter(res => res.r && res.bFor === null),
            tree: payload.tree,
         }
      case 'ERROR_LOADING':
         return {
            error: payload,
            isError: true,
            isLoading: false,
         }
      default:
         return state
   }
}

export default nodeTypesReducer
