/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
   header: {
      height: 80,
   },
   logo: {
      width: 105,
      height: 20,
   },
   sidebar: {
      width: 240,
      collapsedWidth: 65,
      link: 41,
      icon: 20,
   },
   pageLayout: {
      height: 200,
      paddingBottom: 220,
      width: 1450,
   },
   instance: {
      tabs: 240,
   },
}
