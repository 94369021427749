import { Suspense, lazy } from 'react'
import { Route, Routes as RoutesRouter, useLocation } from 'react-router-dom'
import { useConfiguration, useNodeTypes, useSystemDefaults, useThemeEdit } from 'core'

import { Main as MainLayout } from 'layouts'
import { sideBarIcons } from 'helpers/constants'

import Loading from 'views/pages/Loading'
import { GlobalStyles, useTheme } from '@mui/material'
import { useEffect } from 'react'

const DashboardView = lazy(() => import('views/pages/Home'))
const TypeConfigurationView = lazy(() => import('views/pages/Configuration/TypeConfiguration'))
const UIConfigurationView = lazy(() => import('views/pages/Configuration/UIConfiguration'))
const NodeTypesView = lazy(() => import('views/pages/NodeTypes'))
const CreateInstanceView = lazy(() => import('views/pages/Instance/Create'))
const TypeView = lazy(() => import('views/pages/Type'))
const InstanceView = lazy(() => import('views/pages/Instance'))
const ExtendedSearchView = lazy(() => import('views/pages/ExtendedSearch'))
const TreeViewView = lazy(() => import('views/pages/TreeView'))
const EnrichmentView = lazy(() => import('views/pages/Enrichment'))
const NewInstanceView = lazy(() => import('views/pages/NewInstance'))
const ImportView = lazy(() => import('views/pages/CSVImportExport/CSVImport'))
const ExportView = lazy(() => import('views/pages/CSVImportExport/CSVExport'))
const CronJobsView = lazy(() => import('views/pages/CronJobs'))
const CronJobsInstanceView = lazy(() => import('views/pages/CronJobs/Instance'))
const PublishingView = lazy(() => import('views/pages/Publishing'))
const PublishingChannelView = lazy(() => import('views/pages/Publishing/components/Channel'))
const UnitsOfMeasurementView = lazy(() => import('views/pages/UnitsOfMeasurement'))
const FAQView = lazy(() => import('views/pages/Faq'))
const TreeViewConfiguratorView = lazy(() => import('views/pages/TreeViewConfigurator'))
const NotFoundView = lazy(() => import('views/pages/NotFound'))
const PerformanceAll = lazy(() => import('views/pages/PerformanceDashboard'))
const PerformanceCPU = lazy(() => import('views/pages/PerformanceDashboard/components/CPU'))
const PerformanceDatabase = lazy(() =>
   import('views/pages/PerformanceDashboard/components/Database')
)
const PerformanceDisk = lazy(() => import('views/pages/PerformanceDashboard/components/Disk'))
const PerformanceMemory = lazy(() => import('views/pages/PerformanceDashboard/components/Memory'))
const PerformanceNetwork = lazy(() => import('views/pages/PerformanceDashboard/components/Network'))
const PerformanceSystemCache = lazy(() =>
   import('views/pages/PerformanceDashboard/components/SystemCache')
)
const PerformanceThreads = lazy(() => import('views/pages/PerformanceDashboard/components/Threads'))
const PerformanceSearchStrategy = lazy(() =>
   import('views/pages/PerformanceDashboard/components/SearchStrategy')
)

export const PageURLs = {
   FAQ: '/faq',
   UOM: '/units',
   Dashboard: '/dashboard',
   Types: '/types',
   NotFound: '/404',
   Search: '/search',
   Import: '/csv/import',
   Export: '/csv/export',
   Instance: '/instance',
   CronJobs: '/cron-jobs',
   TreeView: '/tree-view',
   Publishing: '/publishing',
   Enrichment: '/enrichment',
   Performance: '/performance',
   TypeConfiguration: '/type-configuration',
   UIConfiguration: '/ui-configuration',
   TreeViewConfigurator: '/tree-view-configurator',
}

export const pimLinks = [
   {
      name: 'Products',
      to: `${PageURLs.Types}/product`,
      icon: sideBarIcons.Product,
   },
   {
      name: 'Categories',
      to: `${PageURLs.Types}/category`,
      icon: sideBarIcons.Category,
   },
   {
      name: 'Catalogs',
      to: `${PageURLs.Types}/catalog`,
      icon: sideBarIcons.Catalog,
   },
   {
      name: 'Tree view',
      to: PageURLs.TreeView,
      icon: sideBarIcons.TreeView,
   },
]

export const configuratorLinks = [
   {
      name: 'Dashboard',
      to: PageURLs.Dashboard,
      icon: sideBarIcons.Dashboard,
   },
   {
      name: 'Types',
      to: PageURLs.Types,
      icon: sideBarIcons.NodeTypes,
   },
   {
      name: 'Enrichment',
      to: PageURLs.Enrichment,
      icon: sideBarIcons.Enrichment,
   },
   {
      name: 'Steps',
      to: `${PageURLs.Types}/step`,
      icon: sideBarIcons.ConfigurationStep,
   },
   {
      name: 'Options',
      to: `${PageURLs.Types}/answer`,
      icon: sideBarIcons.Answer,
   },
   {
      name: 'Results',
      to: `${PageURLs.Types}/result`,
      icon: sideBarIcons.Result,
   },
   {
      name: 'Packages',
      to: `${PageURLs.Types}/package`,
      icon: sideBarIcons.Package,
   },
   {
      name: 'Tree View',
      to: PageURLs.TreeViewConfigurator,
      icon: sideBarIcons.TreeView,
   },
   {
      name: 'Client Answers',
      to: `${PageURLs.Types}/clientanswers`,
      icon: sideBarIcons.ClientAnswers,
   },
   {
      name: 'Configuration',
      to: '/',
      icon: sideBarIcons.Configuration,
      links: [
         {
            name: 'Type Configuration',
            to: PageURLs.TypeConfiguration,
         },
         {
            name: 'UI Configuration',
            to: PageURLs.UIConfiguration,
         },
      ],
   },
]

export const sidebarLinks = [
   {
      name: 'Dashboard',
      to: PageURLs.Dashboard,
      icon: sideBarIcons.Dashboard,
   },
   {
      name: 'Types',
      to: PageURLs.Types,
      icon: sideBarIcons.NodeTypes,
   },
   {
      name: 'Advanced search',
      to: PageURLs.Search,
      icon: sideBarIcons.ExtendedSearch,
   },
   {
      name: 'Enrichment',
      to: PageURLs.Enrichment,
      icon: sideBarIcons.Enrichment,
   },
   {
      name: 'Import/Export',
      icon: sideBarIcons.ImportExport,
      to: '/',
      links: [
         {
            name: 'Import',
            to: PageURLs.Import,
         },
         {
            name: 'Export',
            to: PageURLs.Export,
         },
      ],
   },
   {
      name: 'Jobs',
      to: PageURLs.CronJobs,
      icon: sideBarIcons.CronJobs,
   },
   {
      name: 'Publishing',
      to: PageURLs.Publishing,
      icon: sideBarIcons.Publishing,
   },
   {
      name: 'Configuration',
      to: '/',
      icon: sideBarIcons.Configuration,
      links: [
         {
            name: 'Type Configuration',
            to: PageURLs.TypeConfiguration,
         },
         {
            name: 'UI Configuration',
            to: PageURLs.UIConfiguration,
         },
      ],
   },

   {
      name: 'Units of measurement',
      to: PageURLs.UOM,
      icon: sideBarIcons.Units,
   },
   {
      name: 'Performance',
      to: '/',
      icon: sideBarIcons.Performance,
      links: [
         { name: 'All', to: `${PageURLs.Performance}/all` },
         { name: 'CPU', to: `${PageURLs.Performance}/cpu` },
         { name: 'Memory', to: `${PageURLs.Performance}/memory` },
         { name: 'Database', to: `${PageURLs.Performance}/database` },
         { name: 'Disks', to: `${PageURLs.Performance}/disks` },
         { name: 'Network', to: `${PageURLs.Performance}/network` },
         { name: 'Threads Info', to: `${PageURLs.Performance}/threads` },
         { name: 'System Cache', to: `${PageURLs.Performance}/cache` },
         { name: 'Search Strategy', to: `${PageURLs.Performance}/search-strategy` },
      ],
   },
]

const Routes = () => {
   const location = useLocation()
   const theme = useTheme()

   const { isConfigurationLoading, configuration, hasError } = useConfiguration()
   const { isNodeTypesLoading } = useNodeTypes()
   const { isLoadingSystemDefaults } = useSystemDefaults()
   const { changePrimaryColor } = useThemeEdit()

   useEffect(() => {
      if (configuration && !isConfigurationLoading && !hasError) {
         changePrimaryColor(configuration.conf.primaryColor)
      }
      // eslint-disable-next-line
   }, [configuration, hasError, isConfigurationLoading])

   return !isConfigurationLoading && !isNodeTypesLoading && !isLoadingSystemDefaults ? (
      <Suspense fallback={<MainLayout isSuspense={true} />}>
         <RoutesRouter location={location} key={location.pathname}>
            <Route path="/" element={<MainLayout />}>
               <Route path={PageURLs.TypeConfiguration} element={<TypeConfigurationView />} />
               <Route path={PageURLs.UIConfiguration} element={<UIConfigurationView />} />
               <Route path={PageURLs.Dashboard} element={<DashboardView />} />
               <Route path={PageURLs.Types} element={<NodeTypesView />} />
               <Route
                  path={`${PageURLs.Types}/:nodeType/create`}
                  element={<CreateInstanceView />}
               />
               <Route path={`${PageURLs.Types}/:nodeType`} element={<TypeView />} />
               <Route path={`${PageURLs.Types}/:nodeType/:id`} element={<InstanceView />} />
               <Route path={`${PageURLs.Instance}/:instanceId`} element={<NewInstanceView />} />
               <Route path={PageURLs.Search} element={<ExtendedSearchView />} />
               <Route path={PageURLs.TreeView} element={<TreeViewView />} />
               <Route path={PageURLs.TreeViewConfigurator} element={<TreeViewConfiguratorView />} />
               <Route path={PageURLs.Import} element={<ImportView />} />
               <Route path={PageURLs.Export} element={<ExportView />} />
               <Route path={PageURLs.CronJobs} element={<CronJobsView />} />
               <Route path={`${PageURLs.CronJobs}/:jobId`} element={<CronJobsInstanceView />} />
               <Route path={PageURLs.Publishing} element={<PublishingView />} />
               <Route path={`${PageURLs.Publishing}/create`} element={<PublishingChannelView />} />
               <Route
                  path={`${PageURLs.Publishing}/:channelId`}
                  element={<PublishingChannelView />}
               />
               <Route path={PageURLs.UOM} element={<UnitsOfMeasurementView />} />
               <Route path={PageURLs.FAQ} element={<FAQView />} />
               <Route path={PageURLs.Enrichment} element={<EnrichmentView />} />
               <Route path={`${PageURLs.Performance}/all`} element={<PerformanceAll />} />
               <Route path={`${PageURLs.Performance}/cpu`} element={<PerformanceCPU />} />
               <Route path={`${PageURLs.Performance}/database`} element={<PerformanceDatabase />} />
               <Route path={`${PageURLs.Performance}/disks`} element={<PerformanceDisk />} />
               <Route path={`${PageURLs.Performance}/memory`} element={<PerformanceMemory />} />
               <Route path={`${PageURLs.Performance}/network`} element={<PerformanceNetwork />} />
               <Route path={`${PageURLs.Performance}/cache`} element={<PerformanceSystemCache />} />
               <Route path={`${PageURLs.Performance}/threads`} element={<PerformanceThreads />} />
               <Route
                  path={`${PageURLs.Performance}/search-strategy`}
                  element={<PerformanceSearchStrategy />}
               />
               <Route path={PageURLs.NotFound} element={<NotFoundView />} />
               <Route path="*" render={<NotFoundView />} />
            </Route>
         </RoutesRouter>
         <GlobalStyles styles={{ a: { color: theme.palette.primary.main } }} />
      </Suspense>
   ) : (
      <Loading />
   )
}

export default Routes
