import { useConfiguration } from 'core'

const Logo = () => {
   const { configuration, isConfigurationLoading, hasError } = useConfiguration()

   return hasError || isConfigurationLoading ? (
      <img src="/images/logo.svg" height="28" alt="logo" />
   ) : (
      <img src={configuration.conf.logo} height="32" alt="logo" />
   )
}

export default Logo
