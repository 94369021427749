const userPreferencesReducer = (state, action) => {
   const { type, payload } = action

   switch (type) {
      case 'CHANGE_LANGUAGE':
         localStorage.setItem('language', payload)
         return {
            ...state,
            language: payload,
         }
      case 'TOGGLE_SIDEBAR_COLLAPSE':
         return {
            ...state,
            sidebarCollapsedState: payload,
         }
      default:
         return state
   }
}

export default userPreferencesReducer
