import axios from 'axios'
import { useConfiguration, useNodeTypes } from 'core'
import { serverUrl } from 'helpers/backendDependencies'
import {
   ATTRIBUTE_DELIMITER,
   ATTRIBUTE_GROUP_TYPE_ID,
   CLASSIFICATION_ATTRIBUTE_GROUP_ID,
   CLASSIFICATION_ENUM_ATTRIBUTE_ID,
   ENUM_ATTRIBUTES_TYPE_ID,
   TAB_IDS,
   CLASSIFICATION_MODES,
} from 'helpers/constants'
import { queryParamAsArray } from 'helpers/functions'
import useError from 'helpers/useError'
import { useSnackbar } from 'notistack'
import { createContext, useContext, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import {
   constructRelationBlueprintState,
   getLabelAttributes,
   getLabelDelimiter,
} from 'views/pages/NewInstance/components/TabPanel/components/RelationCard/functions'
import reducer from './reducer'

const initialState = {
   instanceId: null,
   bom: null,
   newRelations: [],
   relationBlueprints: [],
   relationBlueprintsStates: [],
   relationsForRemove: [],
   rules: [],
   modifiers: [],
   attributeGroups: [],
   classificationTrees: [],
   classificationLabels: [],
   classificationModes: [],
   publishingData: {},
   initialValues: {},
   tabValue: { id: TAB_IDS.rootTab, value: 0 },
   isInstanceLoading: true,
   relationsForRemoveRaw: [],
   newRelationsRaw: [],
}

const InstanceContext = createContext(initialState)

const InstanceProvider = ({ children }) => {
   const [state, dispatch] = useReducer(reducer, initialState)
   const { setError } = useError()
   const { enqueueSnackbar } = useSnackbar()
   const { t } = useTranslation(['translation'])
   const { nodeTypes } = useNodeTypes()
   const { configuration } = useConfiguration()
   const { CLASSIFIED } = CLASSIFICATION_MODES
   const { pathname } = useLocation()

   useEffect(() => {
      if (pathname.split('/')[1] !== 'instance') {
         dispatch({
            type: 'RESET_INSTANCE',
         })
      }
   }, [pathname])

   const addToRelationsForRemove = relation => {
      const isMatch = state.relationsForRemoveRaw.some(
         relationForRemove =>
            relationForRemove.relation.id === relation.relation.id &&
            relationForRemove.relationBlueprint.isLeft === relation.relationBlueprint.isLeft &&
            relationForRemove.relationBlueprint.id === relation.relationBlueprint.id
      )

      !isMatch &&
         dispatch({
            type: 'ADD_TO_RELATIONS_FOR_REMOVE',
            payload: relation,
         })
   }

   const addToNewRelations = relation => {
      const isMatch = state.newRelationsRaw.some(
         newRelation =>
            newRelation.relation.id === relation.relation.id &&
            newRelation.relationBlueprint.isLeft === relation.relationBlueprint.isLeft &&
            newRelation.relationBlueprint.id === relation.relationBlueprint.id
      )

      !isMatch &&
         dispatch({
            type: 'ADD_TO_NEW_RELATIONS',
            payload: relation,
         })
   }

   const addArrayOfRelationsToNewRelations = relations => {
      const filteredRelations = relations.filter(
         relation =>
            !state.newRelationsRaw.some(
               newRelation =>
                  newRelation.relation.id === relation.relation.id &&
                  newRelation.relationBlueprint.isLeft === relation.relationBlueprint.isLeft &&
                  newRelation.relationBlueprint.id === relation.relationBlueprint.id
            )
      )

      dispatch({
         type: 'ADD_ARRAY_TO_NEW_RELATIONS',
         payload: filteredRelations,
      })
   }

   const removeFromNewRelations = relation => {
      dispatch({
         type: 'REMOVE_FROM_NEW_RELATIONS',
         payload: relation,
      })
   }

   const removeFromRelationsForRemove = relation => {
      dispatch({
         type: 'REMOVE_FROM_RELATIONS_FOR_REMOVE',
         payload: relation,
      })
   }

   const setTabValue = ({ id, value }) => {
      dispatch({
         type: 'CHANGE_TAB',
         payload: { id, value },
      })
   }

   const formikKey = (attributeName, rule) => {
      return `${attributeName}${ATTRIBUTE_DELIMITER}{"ni":${rule.rk.ni},"nt":${rule.rk.nt},"rn":${rule.rk.rn},"rt":"${rule.rk.rt}","lc":"${rule.rk.lc}"}`
   }

   const makeClassificationAttributeGroups = bom => {
      const generalGroup = [
         {
            nName: t('General'),
            nId: null,
            ch: bom?.ch.filter(group => group.ty !== CLASSIFICATION_ATTRIBUTE_GROUP_ID),
         },
      ]

      let allExistingGroups = bom?.ch.filter(
         group => group.ty === CLASSIFICATION_ATTRIBUTE_GROUP_ID
      )
      return generalGroup.concat(allExistingGroups)
   }

   const makeAttributeGroups = bom => {
      const generalGroup = [
         {
            nName: t('General'),
            nId: null,
            ch: bom?.ch.filter(group => group.ty !== ATTRIBUTE_GROUP_TYPE_ID),
         },
      ]

      let allExistingGroups = bom?.ch.filter(group => group.ty === ATTRIBUTE_GROUP_TYPE_ID)
      return generalGroup.concat(allExistingGroups)
   }

   const createFormikInitialValuesFromBomChildren = (bomChildren, rules) => {
      if (!bomChildren.length) return
      let values = {}

      bomChildren.forEach(child => {
         const getRuleValue = nodeId => {
            return rules.filter(rule => rule.rk.ni === nodeId) || []
         }

         const createKeyValuePair = (attribute, rule) => {
            const key = formikKey(attribute, rule)

            return {
               [key]: rule.rv?.val || '',
            }
         }

         if (child.ch.length > 0) {
            child.ch.forEach(subChild => {
               const ruleArray = getRuleValue(subChild.nId)

               ruleArray.forEach(rule =>
                  Object.assign(values, createKeyValuePair(subChild.nName, rule))
               )
            })
         } else {
            const ruleArray = getRuleValue(child.nId)

            ruleArray.forEach(rule => {
               return Object.assign(values, createKeyValuePair(child.nName, rule))
            })
         }
      })

      return values
   }

   const updatePublishing = async () => {
      try {
         const { data: publishingData } = await axios.get(
            `${serverUrl}/publishing/status?r=${state.instanceId}`
         )

         dispatch({
            type: 'UPDATE_PUBLISHING_DATA',
            payload: publishingData[0],
         })
      } catch (error) {
         setError(error)
      }
   }

   const setInstance = async ({
      instanceId,
      full = true,
      getMainData = false,
      getModifiers = false,
   }) => {
      // this dispatch is here because on save, the relation card is not doing its post request (RelationCard.js:119:28)
      dispatch({ type: 'SET_INSTANCE_LOADING' })
      if (full && !getMainData && !getModifiers) {
         try {
            const { data: mainInstanceData } = await axios.get(
               `${serverUrl}/instances/${instanceId}`
            )
            const { data: modifiersData } = await axios.get(
               `${serverUrl}/modifier/bom/${instanceId}`
            )

            const { data: classificationModes } = await axios.get(
               `${serverUrl}/classification/mode/${instanceId}`
            )

            const { data: publishingData } = await axios.get(
               `${serverUrl}/publishing/status?r=${instanceId}`
            )

            const attributeGroups = makeAttributeGroups(mainInstanceData.bom)

            const rootEnums = attributeGroups
               .map(attributeGroup =>
                  attributeGroup.ch
                     .filter(
                        child =>
                           child.ty === ENUM_ATTRIBUTES_TYPE_ID ||
                           child.ty === CLASSIFICATION_ENUM_ATTRIBUTE_ID
                     )
                     .map(child => child.nId)
               )
               .flat(2)

            let classificationEnums = []
            let classificationAttributes = []
            let classificationRules = []

            if (classificationModes.includes(CLASSIFIED)) {
               const { data: classificationsData } = await axios.get(
                  `${serverUrl}/classification/${instanceId}`
               )

               const createLabelServicePayload = async () => {
                  const allUniqueNodeTypeNames = [
                     ...new Set(classificationsData.map(tree => tree.bom.ty)),
                  ].map(type => nodeTypes.find(nodeType => nodeType.id === type).n.toLowerCase())

                  const allInstanceIds = classificationsData.map(tree => tree.bom.nId)
                  const allAttributeIds = allUniqueNodeTypeNames
                     .map(nodeType => getLabelAttributes(configuration, nodeType))
                     .flat(2)

                  try {
                     const { data: classificationLabels } = await axios.get(
                        `${serverUrl}/labels?${queryParamAsArray(
                           'instanceIds',
                           allInstanceIds
                        )}&${queryParamAsArray('attrIds', allAttributeIds)}`
                     )

                     dispatch({
                        type: 'SET_CLASSIFICATION_LABELS',
                        payload: Object.keys(classificationLabels).map(nId => {
                           const nIdType = classificationsData.find(tree => +tree.bom.nId === +nId)
                              .bom.ty

                           let label =
                              configuration.conf.label.type[
                                 nodeTypes.find(nodeType => nodeType.id === nIdType).n.toLowerCase()
                              ]?.label

                           Object.keys(classificationLabels[nId]).forEach(
                              labelAttribute =>
                                 (label = label.replaceAll(
                                    getLabelDelimiter(labelAttribute),
                                    classificationLabels[nId][labelAttribute][
                                       configuration.conf.localization.defaultLocale.replace(
                                          '_',
                                          '-'
                                       )
                                    ]
                                 ))
                           )
                           return { [nId]: label }
                        }),
                     })
                  } catch (error) {
                     setError(error)
                  }
               }

               classificationsData.length && (await createLabelServicePayload())

               classificationEnums = classificationsData
                  .map(tree =>
                     makeClassificationAttributeGroups(tree.bom)
                        .map(attributeGroup =>
                           attributeGroup.ch
                              .filter(
                                 child =>
                                    child.ty === CLASSIFICATION_ENUM_ATTRIBUTE_ID ||
                                    child.ty === ENUM_ATTRIBUTES_TYPE_ID
                              )
                              .map(child => child.nId)
                        )
                        .flat(2)
                  )
                  .flat(1)

               classificationsData.length &&
                  classificationsData.forEach(classificationTree => {
                     classificationAttributes = classificationAttributes.concat(
                        classificationTree.bom.ch
                     )
                     classificationRules = classificationRules.concat(classificationTree.rules)
                  })

               dispatch({
                  type: 'SET_CLASSIFICATION_TREES',
                  payload: classificationsData,
               })
            }

            const allEnums = rootEnums.concat(classificationEnums)

            const { data: enumsData } = await axios.get(
               `${serverUrl}/instances/rules/enum?${allEnums
                  .map(enumValue => `nodeId=${enumValue}`)
                  .join('&')}`
            )

            const relationBlueprintsStates = mainInstanceData.relationBlueprints.map(
               relationBlueprint => ({
                  id: constructRelationBlueprintState(relationBlueprint),
                  shouldGetInitialRelations: true,
                  initialRelations: [],
               })
            )

            dispatch({
               type: 'SET_INSTANCE',
               payload: {
                  instanceId: +instanceId,
                  ...mainInstanceData,
                  relationBlueprintsStates,
                  modifiers: modifiersData,
                  attributeGroups,
                  enums: enumsData,
                  classificationModes,
                  publishingData: publishingData[0],
               },
            })

            let initialValues = createFormikInitialValuesFromBomChildren(
               mainInstanceData.bom.ch.concat(classificationAttributes),
               mainInstanceData.rules.concat(classificationRules)
            )

            dispatch({
               type: 'SET_FORMIK_INITIAL_VALUES',
               payload: initialValues,
            })
         } catch (error) {
            setError(error)
         }
      } else {
         try {
            getMainData && (await axios.get(`${serverUrl}/instances/${instanceId}`))
            getModifiers && (await axios.get(`${serverUrl}/modifier/bom/${instanceId}`))
         } catch (error) {
            setError(error)
         }
      }
   }

   const transformFormikValuesToRules = formikValues => {
      return Object.keys(formikValues).map(data => {
         const ruleKey = JSON.parse(data.split(ATTRIBUTE_DELIMITER)[1])
         const ruleValue = formikValues[data]

         return { rk: ruleKey, rv: { val: ruleValue } }
      })
   }

   const saveInstance = async (editedValues, conflictingRules = []) => {
      const {
         newRelations,
         relationsForRemove,
         bom: { nId, nName, ty },
      } = state

      const allRules = transformFormikValuesToRules(editedValues)
      let nonConflictingRules = []

      const rules = conflictingRules.length
         ? nonConflictingRules.concat(conflictingRules)
         : allRules

      const request = {
         bom: {
            ch: null,
            nId,
            nName,
            ty,
         },
         newRelations,
         relationsForRemove,
         rules,
      }

      try {
         await axios.put(`${serverUrl}/instances`, request)

         setInstance({ instanceId: state.instanceId })

         enqueueSnackbar(t('SuccessfulSave'), { variant: 'success' })
      } catch (error) {
         setError(error)
      }
   }

   const setRelationBlueprintState = (id, initialRelations) => {
      const payload = { id, initialRelations }
      dispatch({
         type: 'UPDATE_RELATION_BLUEPRINT_STATE',
         payload: payload,
      })
   }

   const createRelationBlueprintStateFromRelationBlueprints = relationBlueprints => {
      dispatch({
         type: 'CREATE_RELATION_BLUEPRINT_STATE_FROM_RELATION_BLUEPRINTS',
         payload: relationBlueprints,
      })
   }

   const updateInitialRelations = (id, newState) => {
      const payload = { id, newState }

      dispatch({
         type: 'EDIT_INITIAL_RELATIONS_IN_RELATION_BLUEPRINT_STATE',
         payload,
      })
   }

   return (
      <InstanceContext.Provider
         value={{
            instanceId: state.instanceId,
            bom: state.bom,
            newRelations: state.newRelations,
            relationBlueprints: state.relationBlueprints,
            relationBlueprintsStates: state.relationBlueprintsStates,
            relationsForRemove: state.relationsForRemove,
            rules: state.rules,
            modifiers: state.modifiers,
            attributeGroups: state.attributeGroups,
            publishingData: state.publishingData,
            classificationTrees: state.classificationTrees,
            classificationLabels: state.classificationLabels,
            classificationModes: state.classificationModes,
            enums: state.enums,
            isInstanceLoading: state.isInstanceLoading,
            initialValues: state.initialValues,
            tabValue: state.tabValue,
            relationsForRemoveRaw: state.relationsForRemoveRaw,
            newRelationsRaw: state.newRelationsRaw,
            setTabValue,
            setInstance,
            saveInstance,
            addToRelationsForRemove,
            addToNewRelations,
            removeFromNewRelations,
            removeFromRelationsForRemove,
            makeAttributeGroups,
            makeClassificationAttributeGroups,
            formikKey,
            transformFormikValuesToRules,
            updatePublishing,
            createFormikInitialValuesFromBomChildren,
            setRelationBlueprintState,
            createRelationBlueprintStateFromRelationBlueprints,
            updateInitialRelations,
            addArrayOfRelationsToNewRelations,
         }}
         displayName="Instance"
      >
         {children}
      </InstanceContext.Provider>
   )
}

const useInstance = () => {
   const context = useContext(InstanceContext)
   if (context === undefined) {
      throw new Error('useInstance can only be used inside InstanceProvider')
   }
   return context
}

export { InstanceProvider, useInstance }
