// Table
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import Check from '@mui/icons-material/Check'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Clear from '@mui/icons-material/Clear'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import Edit from '@mui/icons-material/Edit'
import FilterList from '@mui/icons-material/FilterList'
import FirstPage from '@mui/icons-material/FirstPage'
import LastPage from '@mui/icons-material/LastPage'
import Remove from '@mui/icons-material/Remove'
import SaveAlt from '@mui/icons-material/SaveAlt'
import Search from '@mui/icons-material/Search'
import ViewColumn from '@mui/icons-material/ViewColumn'
import Circle from '@mui/icons-material/FiberManualRecord'
import CheckCircle from '@mui/icons-material/CheckCircleOutline'
import UncheckCircle from '@mui/icons-material/HighlightOff'
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined'

// Sidebar
import Dashboard from '@mui/icons-material/AssessmentTwoTone'
import Configuration from '@mui/icons-material/SettingsTwoTone'
import Category from '@mui/icons-material/CategoryTwoTone'
import Performance from '@mui/icons-material/SettingsInputComponentTwoTone'
import FAQ from '@mui/icons-material/LiveHelpTwoTone'
import CallSplitIcon from '@mui/icons-material/CallSplitTwoTone'
import SearchIcon from '@mui/icons-material/SearchTwoTone'
import ImportContactsIcon from '@mui/icons-material/ImportContactsTwoTone'
import AddBoxIcon from '@mui/icons-material/AddBoxTwoTone'
import EnrichmentIcon from '@mui/icons-material/EventNoteTwoTone'
import ImportExportIcon from '@mui/icons-material/ImportExportTwoTone'
import PublishIcon from '@mui/icons-material/PublishTwoTone'
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircleTwoTone'
import AccountTreeIcon from '@mui/icons-material/AccountTreeTwoTone'
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone'
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone'
import LocalMallIcon from '@mui/icons-material/LocalMallTwoTone'
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturnedTwoTone'
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'

// Error
import Error from '@mui/icons-material/Error'

// Instance
import ShoppingBasket from '@mui/icons-material/ShoppingBasket'
import Refresh from '@mui/icons-material/Refresh'
import Save from '@mui/icons-material/Save'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddIcon from '@mui/icons-material/Add'
import LanguageIcon from '@mui/icons-material/Language'

// Relation Card
import CloseIcon from '@mui/icons-material/Close'
import Add from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

// Instance popup
import AspectRatioIcon from '@mui/icons-material/AspectRatio'

// CSV Import/Export
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

// Backend IDs
export const maxIntNumber = 2147483646
export const GENERAL_ATTRIBUTE_TYPE_ID = 1
export const LOCALIZED_ATTRIBUTE_TYPE_ID = 2
export const ATTRIBUTE_GROUP_TYPE_ID = 3
export const ENUM_ATTRIBUTES_TYPE_ID = 7
export const RANGE_ATTRIBUTES_TYPE_ID = 9
export const CLASSIFICATION_GENERAL_ATTRIBUTE_ID = 4
export const LOCALIZED_CLASSIFICATION_ATTRIBUTE_ID = 5
export const CLASSIFICATION_ATTRIBUTE_GROUP_ID = 6
export const CLASSIFICATION_ENUM_ATTRIBUTE_ID = 8
export const CLASSIFICATION_RANGE_ATTRIBUTE_ID = 10
export const SIMPLE_RULE_TYPES = [
   { name: 'String', value: 'STRING' },
   { name: 'Double', value: 'DOUBLE' },
   { name: 'Integer', value: 'INTEGER' },
   { name: 'Long', value: 'LONG' },
   { name: 'Date', value: 'DATE' },
   { name: 'Boolean', value: 'BOOLEAN' },
   { name: 'Rich text', value: 'RICH_TEXT' },
   { name: 'Media', value: 'MEDIA' },
   { name: 'Unit of measurement', value: 'UNIT_OF_MEASUREMENT' },
]
export const ENUM_RULE_TYPES = [{ name: 'String', value: 'STRING' }]
export const RANGE_RULE_TYPES = [
   { name: 'String', value: 'STRING' },
   { name: 'Double', value: 'DOUBLE' },
   { name: 'Integer', value: 'INTEGER' },
   { name: 'Long', value: 'LONG' },
   { name: 'Date', value: 'DATE' },
]
export const REFERENCE_RULE_TYPES = [
   { name: 'One-To-Many', value: 1 },
   { name: 'Many-To-Many', value: 2 },
]

export const HISTORY_ACTIONS = [
   { name: 'CREATE', icon: <Add />, title: 'Create' },
   { name: 'MODIFY', icon: <Edit />, title: 'Modify' },
   { name: 'DELETE', icon: <DeleteIcon />, title: 'Delete' },
]

export const ATTRIBUTES_FULL_INFO = [
   { id: GENERAL_ATTRIBUTE_TYPE_ID, title: 'Basic', ruleTypes: SIMPLE_RULE_TYPES },
   { id: LOCALIZED_ATTRIBUTE_TYPE_ID, title: 'Localized', ruleTypes: SIMPLE_RULE_TYPES },
   {
      id: ENUM_ATTRIBUTES_TYPE_ID,
      title: 'Enum',
      ruleTypes: ENUM_RULE_TYPES,
   },
   {
      id: RANGE_ATTRIBUTES_TYPE_ID,
      title: 'Range',
      ruleTypes: RANGE_RULE_TYPES,
   },
   {
      id: 'reference',
      title: 'Reference',
      ruleTypes: REFERENCE_RULE_TYPES,
   },
]
export const CLASSIFICATION_ATTRIBUTES_FULL_INFO = [
   { id: CLASSIFICATION_GENERAL_ATTRIBUTE_ID, title: 'Basic', ruleTypes: SIMPLE_RULE_TYPES },
   { id: LOCALIZED_CLASSIFICATION_ATTRIBUTE_ID, title: 'Localized', ruleTypes: SIMPLE_RULE_TYPES },
   {
      id: CLASSIFICATION_ENUM_ATTRIBUTE_ID,
      title: 'Enum',
      ruleTypes: ENUM_RULE_TYPES,
   },
   {
      id: CLASSIFICATION_RANGE_ATTRIBUTE_ID,
      title: 'Range',
      ruleTypes: RANGE_RULE_TYPES,
   },
]

// Table Icons
export const tableIcons = {
   Add: <AddBoxIcon />,
   Check: <Check />,
   Circle: <Circle />,
   CheckCircle: <CheckCircle />,
   UncheckCircle: <UncheckCircle />,
   Clear: <Clear />,
   Delete: <DeleteOutline />,
   DetailPanel: <ChevronRight />,
   Edit: <Edit />,
   Export: <SaveAlt />,
   Filter: <FilterList />,
   FirstPage: <FirstPage />,
   LastPage: <LastPage />,
   NextPage: <ChevronRight />,
   PreviousPage: <ChevronLeft />,
   ResetSearch: <Clear />,
   Search: <Search />,
   SortArrow: <ArrowDownwardIcon />,
   ThirdStateCheck: <Remove />,
   ViewColumn: <ViewColumn />,
}

// Relation card
export const relationCardIcons = {
   Search: <SearchIcon />,
   Add: <AddIcon />,
   Close: <CloseIcon />,
   Remove: <DeleteIcon />,
   FirstPage: <FirstPage />,
   LastPage: <LastPage />,
   NextPage: <ChevronRight />,
   PreviousPage: <ChevronLeft />,
}

// Instance Popup
export const instancePopupIcons = {
   Expand: <AspectRatioIcon />,
   Close: <CloseIcon />,
}

// CSV Import/Export Icons
export const csvIcons = {
   ImportIcon: <CloudUploadIcon />,
   ExportIcon: <CloudDownloadIcon />,
}

export const sideBarIcons = {
   Dashboard: Dashboard,
   Performance: Performance,
   NodeTypes: CallSplitIcon,
   Product: AddBoxIcon,
   CronJobs: SwapHorizontalCircleIcon,
   Category: Category,
   Catalog: ImportContactsIcon,
   FAQ: FAQ,
   ExtendedSearch: SearchIcon,
   Enrichment: EnrichmentIcon,
   Units: StraightenOutlinedIcon,
   ImportExport: ImportExportIcon,
   Publishing: PublishIcon,
   Configuration: Configuration,
   TreeView: AccountTreeIcon,
   ConfigurationStep: HelpTwoToneIcon,
   Answer: ChatBubbleTwoToneIcon,
   Result: AssignmentReturnedIcon,
   Package: LocalMallIcon,
   ClientAnswers: AccountCircleTwoToneIcon,
}

export const errorIcons = {
   Icon: <Error />,
}

export const instanceIcons = {
   ShoppingBasket: <ShoppingBasket />,
   Refresh: <Refresh />,
   Save: <Save />,
   Back: <ArrowBackIcon />,
   RelationAdd: <AddIcon />,
   RelationDelete: <DeleteOutline />,
   Globe: <LanguageIcon />,
}

export const searchIcons = {
   Add: <Add />,
}

export const performanceDashboardConfiguration = {
   cpu: {
      receiveDataInterval: 1000,
   },
   database: {
      receiveDataInterval: 600000,
   },
   disk: {
      receiveDataInterval: 60000,
   },
   memory: {
      receiveDataInterval: 5000,
   },
   network: {
      receiveDataInterval: 5000,
   },
   threads: {
      receiveDataInterval: 60000,
   },
}

export const ALL_ATTRIBUTES_TAB_VALUE = 'allAttributes'
export const CLASSIFICATION_ENRICHMENT_TAB_VALUE = 'classificationEnrichment'
export const TAB_IDS = {
   rootTab: 'rootTab',
   classificationTab: 'classificationTab',
   allAttributesTab: 'allAttributesTab',
   classificationEnrichmentTab: 'classificationEnrichmentTab',
}
export const RELATIONS_PER_PAGE = 5
export const RANGE_ATTRIBUTE_DELIMITER = '#!@!#'
export const ATTRIBUTE_DELIMITER = '#!_!#'
export const CLASSIFICATION_MODES = {
   CLASSIFIED: 'CLASSIFIED',
   CLASSIFICATOR: 'CLASSIFICATOR',
   NONE: 'NONE',
}
