import { createContext, useContext, useEffect, useReducer } from 'react'
import reducer from './reducer'
import { LANGUAGE } from 'config/constants/userPreferences'
import { useMediaQuery, useTheme } from '@mui/material'

const initialState = {
   language: localStorage.getItem('language') || LANGUAGE,
   sidebarCollapsedState: false,
}

const UserPreferencesContext = createContext(initialState)

const UserPreferencesProvider = ({ children }) => {
   const theme = useTheme()
   const [state, dispatch] = useReducer(reducer, initialState)
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

   useEffect(() => {
      localStorage.getItem('language') === null && localStorage.setItem('language', LANGUAGE)
   }, [])

   useEffect(() => {
      if (isMobile) {
         dispatch({
            type: 'TOGGLE_SIDEBAR_COLLAPSE',
            payload: false,
         })
      }
   }, [isMobile])

   const changeLanguage = language => {
      dispatch({
         type: 'CHANGE_LANGUAGE',
         payload: language,
      })
   }

   const toggleSidebarCollapse = () => {
      dispatch({
         type: 'TOGGLE_SIDEBAR_COLLAPSE',
         payload: !state.sidebarCollapsedState,
      })
   }

   return (
      <UserPreferencesContext.Provider
         value={{
            language: state.language,
            sidebarCollapsedState: state.sidebarCollapsedState,
            changeLanguage,
            toggleSidebarCollapse,
         }}
         displayName="User Preferences"
      >
         {children}
      </UserPreferencesContext.Provider>
   )
}

const useUserPreferences = () => {
   const context = useContext(UserPreferencesContext)
   if (context === undefined) {
      throw new Error('useUserPreferences can only be used inside UserPreferencesProvider')
   }
   return context
}

export { UserPreferencesProvider, useUserPreferences }
