const configurationReducer = (state, action) => {
   const { type, payload } = action

   switch (type) {
      case 'CONFIGURATION_LOADED':
      case 'SET_NEW_CONFIGURATION':
         return {
            error: null,
            hasError: false,
            isConfigurationLoading: false,
            configuration: payload,
         }
      case 'ERROR_LOADING':
         return {
            hasError: true,
            isConfigurationLoading: false,
            error: payload,
         }
      default:
         return state
   }
}

export default configurationReducer
