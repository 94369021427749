import { useState } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import { Button, Collapse, ListItem, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
   item: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
   },
   button: {
      padding: theme.spacing(1.5, 2.5),
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
      justifyContent: 'flex-start',
      letterSpacing: 0,
      textTransform: 'none',
      width: '100%',
      borderRadius: 0,
   },
   icon: {
      marginRight: theme.spacing(2),
      fontSize: theme.spacing(3),
   },
   title: {
      marginRight: 'auto',
      fontSize: 14,
   },
   subCategoryActive: {
      color: theme.palette.grey[800],
      '& $title': {
         fontWeight: theme.typography.fontWeightBold,
      },
   },
   childIcon: {
      marginRight: theme.spacing(2),
      fontSize: 6,
      width: theme.spacing(3),
      color: theme.palette.primary.main,
   },
   active: {
      background: theme.palette.grey[200],
      borderRight: `3px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      '& $title': {
         fontWeight: theme.typography.fontWeightMedium,
      },
      '& $icon': {
         color: theme.palette.primary.main,
      },
   },
   rotateChevron: {
      transform: 'rotate(90deg)',
   },
   subMenuCollapsed: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: -201,
      background: theme.palette.background.paper,
      width: 200,
      transition: 'all 0.2s',
      boxShadow: theme.shadows[1],
   },
}))

const NavItem = ({
   className,
   href,
   icon: Icon,
   title,
   children = [],
   sidebarCollapsedState,
   ...rest
}) => {
   const classes = useStyles()
   const { t } = useTranslation()

   const [openCollapse, setOpenCollapse] = useState(false)

   const content = (
      <>
         {Icon && <Icon className={classes.icon} />}
         <Typography variant="body1" className={classes.title} noWrap>
            {t(title)}
         </Typography>
      </>
   )

   return (
      <div style={{ position: 'relative' }}>
         <ListItem className={clsx(classes.item, className)} disableGutters {...rest}>
            {children.length ? (
               <Button
                  onClick={() => setOpenCollapse(prev => !prev)}
                  className={classes.button}
                  endIcon={
                     children.length ? (
                        <ChevronRightIcon
                           style={
                              !sidebarCollapsedState
                                 ? { transition: 'all 0.3s' }
                                 : { transition: 'all 0.3s', marginLeft: -24 }
                           }
                           className={clsx(openCollapse && classes.rotateChevron)}
                        />
                     ) : (
                        ''
                     )
                  }
               >
                  {content}
               </Button>
            ) : (
               <RouterLink to={href} style={{ width: '100%' }}>
                  {({ isActive }) => (
                     <Button className={clsx(classes.button, isActive && classes.active)}>
                        {content}
                     </Button>
                  )}
               </RouterLink>
            )}
         </ListItem>
         <Collapse
            in={openCollapse}
            className={clsx(sidebarCollapsedState && classes.subMenuCollapsed)}
         >
            {children.map(link => (
               <ListItem key={link.name} style={{ padding: 0 }}>
                  <RouterLink to={link.to}>
                     {({ isActive }) => (
                        <Button
                           className={clsx(classes.button, isActive && classes.subCategoryActive)}
                        >
                           <FiberManualRecordIcon className={clsx(classes.childIcon)} />
                           <span className={classes.title}>{t(link.name)}</span>
                        </Button>
                     )}
                  </RouterLink>
               </ListItem>
            ))}
         </Collapse>
      </div>
   )
}

export default NavItem
