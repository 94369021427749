import axios from 'axios'
import { serverUrl } from './backendDependencies'

// Transform "Some Link" to "some-link"
export const linkify = link => {
   const regex = / /gi
   const finalLink = link.replace(regex, '-')

   return finalLink.toLowerCase()
}

export const getData = async url => {
   try {
      let result = await axios(url, {
         headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
         },
      })

      return result.data
   } catch (error) {
      console.error(error)
   }
}

export const groupBy = key => array =>
   array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key]
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
      return objectsByKeyValue
   }, {})

export const humanFileSize = size => {
   let i = Math.floor(Math.log(size) / Math.log(1024))
   return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
}

export const fetchRules = nodeType => {
   return axios.get(`${serverUrl}/instances/${nodeType}/rules/attributes`)
}

export const fetchModifiers = nodeType => {
   return axios.get(`${serverUrl}/modifier/bom/${nodeType}`)
}

export const queryParamAsArray = (string, array) => {
   return array
      .map(id => {
         return `${string}=${id}`
      })
      .join('&')
}

/**
 * Checks if a JavaScript value is empty
 * @example
 *    isEmpty(null); // true
 *    isEmpty(undefined); // true
 *    isEmpty(''); // true
 *    isEmpty([]); // true
 *    isEmpty({}); // true
 * @param {any} value - item to test
 * @returns {boolean} true if empty, otherwise false
 */
export const isEmpty = value => {
   return (
      value === null || // check for null
      value === undefined || // check for undefined
      value === '' || // check for empty string
      (Array.isArray(value) && value.length === 0) || // check for empty array
      (typeof value === 'object' && Object.keys(value).length === 0) // check for empty object
   )
}

export const AttributeService = {
   getAttributeValueByInstance: (instance, qualifier, language = 'en-GB') =>
      instance.attributes.find(attribute => attribute.qualifier === qualifier).values[language],
   getAttributeByQualifier: (attributes, qualifier) =>
      attributes.find(attr => attr.qualifier === qualifier),
   getAttributeValue: (attribute, language = 'en-GB') => attribute.values[language],
   getAttributeId: attribute => attribute.attributeId,
   getAttributeGroupId: attribute => attribute.attributeGroupId,
   getRelationsBlueprintInstanceIdsByInstance: (instance, qualifier) =>
      instance.relationsBlueprints.find(
         relationsBlueprint => relationsBlueprint.qualifier === qualifier
      ).instanceIds,
   getAnswersForStep: function (answers, step) {
      const answerIds = this.getRelationsBlueprintInstanceIdsByInstance(step, 'answers')
      return answers.filter(answer => answerIds.includes(answer.id))
   },
   getStepForAnswer: function (steps, answer) {
      const stepIds = this.getRelationsBlueprintInstanceIdsByInstance(answer, 'nextStep')
      return steps.filter(step => stepIds.includes(step.id))
   },
   getResultForAnswer: function (results, answer) {
      const resultIds = this.getRelationsBlueprintInstanceIdsByInstance(answer, 'result')
      return results.filter(step => resultIds.includes(step.id))
   },
}
