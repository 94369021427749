import { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

const useError = () => {
   const { enqueueSnackbar } = useSnackbar()
   const { t, i18n } = useTranslation()
   const [error, setError] = useState(null)
   const [errorCode, setErrorCode] = useState(0)

   useEffect(() => {
      if (error) {
         setErrorCode(error.response?.data.code)
         const errorMessage = error.response?.data.message

         errorMessage &&
            enqueueSnackbar(
               i18n.exists(`errors:Error${error.response.data.code}`)
                  ? t(`errors:Error${error.response.data.code}`)
                  : errorMessage,
               {
                  variant: 'error',
               }
            )
      }
      // eslint-disable-next-line
   }, [error])

   return { errorMessage: t(`errors:Error${errorCode}`), setError }
}

export default useError
