import { createContext, useContext, useEffect, useReducer } from 'react'
import { CONFIGURATION_PROFILE_NAME, serverUrl } from 'helpers/backendDependencies'
import reducer from './reducer'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import useError from 'helpers/useError'

const initialState = {
   error: null,
   hasError: false,
   isConfigurationLoading: true,
   configuration: {},
}

const ConfigurationContext = createContext({})

const ConfigurationProvider = ({ children }) => {
   const [state, dispatch] = useReducer(reducer, initialState)
   const { enqueueSnackbar } = useSnackbar()
   const { setError } = useError()

   useEffect(() => {
      loadConfiguration()
      // eslint-disable-next-line
   }, [])

   const loadConfiguration = async () => {
      try {
         const result = await axios(`${serverUrl}/ui-configurations/${CONFIGURATION_PROFILE_NAME}`)

         result.data.conf = JSON.parse(result.data.conf)

         dispatch({
            type: 'CONFIGURATION_LOADED',
            payload: result.data,
         })
      } catch (error) {
         dispatch({
            type: 'ERROR_LOADING',
            payload: error.message,
         })

         setError(error)
      }
   }

   const setNewConfiguration = async configuration => {
      try {
         await axios.put(`${serverUrl}/ui-configurations`, {
            sysId: configuration.sysId,
            conf: `${JSON.stringify(configuration.conf)}`,
         })
         enqueueSnackbar('Configuration was successfully updated', { variant: 'success' })
         dispatch({
            type: 'SET_NEW_CONFIGURATION',
            payload: configuration,
         })
      } catch (error) {
         enqueueSnackbar('There was a problem and the configuration was not updated', {
            variant: 'error',
         })
      }
   }

   return (
      <ConfigurationContext.Provider
         value={{
            error: state.error,
            hasError: state.hasError,
            isConfigurationLoading: state.isConfigurationLoading,
            configuration: state.configuration,
            setNewConfiguration,
         }}
         displayName="Configuration"
      >
         {children}
      </ConfigurationContext.Provider>
   )
}

const useConfiguration = () => {
   const context = useContext(ConfigurationContext)
   if (context === undefined) {
      throw new Error('useConfiguration can only be used inside ConfigurationProvider')
   }
   return context
}

export { ConfigurationProvider, useConfiguration }
