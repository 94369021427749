import { createContext, useReducer, useEffect, useContext } from 'react'
import axios from 'axios'
import reducer from './reducer'
import { serverUrl } from 'helpers/backendDependencies'
import { useSnackbar } from 'notistack'
import { queryParamAsArray } from 'helpers/functions'
import { useTranslation } from 'react-i18next'
import useError from 'helpers/useError'

const initialState = {
   errorSystemDefaults: null,
   isErrorSystemDefaults: false,
   isLoadingSystemDefaults: true,
   unitsOfMeasurement: [],
   unitsOfMeasurementObject: {},
}

const SystemDefaultsContext = createContext(initialState)

const SystemDefaultsProvider = ({ children }) => {
   const { enqueueSnackbar } = useSnackbar()
   const [state, dispatch] = useReducer(reducer, initialState)
   const { t } = useTranslation(['uoms'])
   const { setError } = useError()

   useEffect(() => {
      loadUoms()
      // eslint-disable-next-line
   }, [])

   const loadUoms = async () => {
      try {
         const uomsResult = await axios(`${serverUrl}/uoms`)
         const object = uomsResult.data.reduce((obj, cur, i) => {
            return { ...obj, [cur.id]: cur }
         }, {})

         dispatch({
            type: 'UNITS_OF_MEASUREMENT_LOADED',
            payload: { unitsArray: uomsResult.data, unitsObject: object },
         })
      } catch (error) {
         dispatch({
            type: 'ERROR_LOADING_UOMS',
            payload: error.message,
         })
         setError(error)
      }
   }

   const deleteUoms = async (idArray, modalValues) => {
      const { setIsLoading, onClose } = modalValues
      try {
         setIsLoading(true)
         let queryParamArray = queryParamAsArray('id', idArray)
         await axios.delete(`${serverUrl}/uoms/?${queryParamArray}`)
         enqueueSnackbar(t('SuccessfullyDeletedUnits'), {
            variant: 'success',
         })
         idArray.forEach(id =>
            dispatch({
               type: 'UNIT_OF_MEASUREMENT_DELETED',
               payload: id,
            })
         )
         setIsLoading(false)
         onClose()
      } catch (error) {
         setError(error)
         setIsLoading(false)
         onClose()
      }
   }

   const setNewUnit = async (unitBody, modalValues) => {
      const { setIsLoading, setUnitBody, setDisableActionButton, onClose } = modalValues
      try {
         setDisableActionButton(true)
         setIsLoading(true)
         const { data } = await axios.post(`${serverUrl}/uoms`, unitBody)
         enqueueSnackbar(t('UnitCreatedSuccessfully'), {
            variant: 'success',
         })
         setIsLoading(false)
         setDisableActionButton(false)
         setUnitBody({
            name: '',
            symbol: '',
            quantityName: '',
            dimension: '',
         })
         dispatch({
            type: 'UNIT_OF_MEASUREMENT_CREATED',
            payload: data,
         })
         onClose()
      } catch (error) {
         setError(error)
         setIsLoading(false)
         setUnitBody({
            name: '',
            symbol: '',
            quantityName: '',
            dimension: '',
         })
         setDisableActionButton(false)
         onClose()
      }
   }

   const editUnit = async (editedUnitBody, modalValues) => {
      const {
         setIsLoading,
         setEditedUnitBody,
         setDisableActionButton,
         unitRow,
         onClose,
      } = modalValues
      setDisableActionButton(true)
      try {
         setIsLoading(true)
         const { data } = await axios.put(`${serverUrl}/uoms`, editedUnitBody)
         enqueueSnackbar(t('UnitEditedSuccessfully'), {
            variant: 'success',
         })
         setIsLoading(false)
         setDisableActionButton(false)
         setEditedUnitBody(editedUnitBody)
         dispatch({
            type: 'UNIT_OF_MEASUREMENT_EDITED',
            payload: [data],
         })
         onClose()
      } catch (error) {
         setError(error)
         setIsLoading(false)
         setEditedUnitBody({
            id: unitRow.id,
            name: unitRow.name ? unitRow.name : '',
            symbol: unitRow.symbol ? unitRow.symbol : '',
            quantityName: unitRow.quantityName ? unitRow.quantityName : '',
            dimension: unitRow.dimension ? unitRow.dimension : '',
         })
         setDisableActionButton(false)
         onClose()
      }
   }
   return (
      <SystemDefaultsContext.Provider
         value={{
            errorSystemDefaults: state.errorSystemDefaults,
            isErrorSystemDefaults: state.isErrorSystemDefaults,
            isLoadingSystemDefaults: state.isLoadingSystemDefaults,
            unitsOfMeasurement: state.unitsOfMeasurement,
            unitsOfMeasurementObject: state.unitsOfMeasurementObject,
            loadUoms,
            setNewUnit,
            deleteUoms,
            editUnit,
         }}
         displayName="System Defaults"
      >
         {children}
      </SystemDefaultsContext.Provider>
   )
}

const useSystemDefaults = () => {
   const context = useContext(SystemDefaultsContext)
   if (context === undefined) {
      throw new Error('useSystemDefaults can only be used inside SystemDefaultsProvider')
   }
   return context
}

export { SystemDefaultsProvider, useSystemDefaults }
