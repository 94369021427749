import { IconButton, MenuItem, Popover, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useUserPreferences } from 'core/contexts/UserPreferences'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
   flagIcon: {
      marginRight: theme.spacing(1),
   },
}))

const Language = ({ ...rest }) => {
   const classes = useStyles()
   const { t, i18n } = useTranslation()
   const { language, changeLanguage } = useUserPreferences()

   const [anchorEl, setAnchorEl] = useState(null)

   const handleClick = event => {
      setAnchorEl(event.currentTarget)
   }

   const handleClose = () => {
      setAnchorEl(null)
   }

   const handleSelectLanguage = language => {
      setAnchorEl(null)
      i18n.changeLanguage(language)
      changeLanguage(language)
   }

   const open = Boolean(anchorEl)

   return (
      <div className={classes.root} {...rest}>
         <Tooltip title={t('ChangeLanguage')} placement="bottom" style={{ marginRight: 8 }}>
            <IconButton onClick={handleClick} size="large">
               <img src={`/images/flags/${language}.png`} width="24" alt="flag" />
            </IconButton>
         </Tooltip>
         <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'center',
            }}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'center',
            }}
         >
            <MenuItem
               style={{ paddingTop: 8, paddingBottom: 8 }}
               onClick={() => handleSelectLanguage('en-UK')}
            >
               <img
                  width="16"
                  height="16"
                  className={classes.flagIcon}
                  src="/images/flags/en-UK.png"
                  alt="english flag"
               />
               {t('English')}
            </MenuItem>
            <MenuItem
               style={{ paddingTop: 8, paddingBottom: 8 }}
               onClick={() => handleSelectLanguage('bg-BG')}
            >
               <img
                  width="16"
                  height="16"
                  className={classes.flagIcon}
                  src="/images/flags/bg-BG.png"
                  alt="bulgarian flag"
               />
               {t('Bulgarian')}
            </MenuItem>
         </Popover>
      </div>
   )
}

export default Language
