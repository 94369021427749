import { createTheme } from '@mui/material/styles'

import palette from './palette'
import sizing from './sizing'
import overrides from './overrides'

const theme = createTheme({
   palette,
   sizing,
   components: overrides,
   zIndex: {
      sideBar: 1099,
   },
   breakpoints: {
      values: {
         xs: 0,
         sm: 600,
         md: 960,
         lg: 1500,
         xl: 1920,
      },
   },
})

export default theme
