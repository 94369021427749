import { useEffect } from 'react'
import './assets/styles/base.css'
import 'translations'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { BrowserRouter as Router } from 'react-router-dom'
import StoreProvider from './core/store'
import { interceptors } from 'axios'
import Routes from 'Routes'
import { CssBaseline } from '@mui/material'
import { routerBasename } from 'helpers/backendDependencies'
import UserService from 'services/UserService'

console.log(
   `%cMorph-UI App Version: 1.0.0`,
   'background: #3F51B5; color:#FFF; padding:5px; border-radius: 5px; line-height: 26px;'
)

interceptors.response.use(
   response => {
      return response
   },
   error => {
      if (error.response.status === 401) {
         UserService.doLogin()
      }

      return Promise.reject(error)
   }
)

const App = () => {
   useEffect(() => {
      const interval = setInterval(() => {
         UserService.updateToken()
      }, 60000)
      return () => clearInterval(interval)
   }, [])

   return (
      <Router basename={routerBasename}>
         <StoreProvider>
            <CssBaseline />
            <Routes />
         </StoreProvider>
      </Router>
   )
}

export default App
