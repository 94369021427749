/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
   primary: {
      main: '#007dff',
   },
   secondary: {
      main: '#fdaf4d',
   },
   success: {
      main: '#4caf50',
   },
   info: {
      main: '#00cfd5',
   },
   warning: {
      main: '#f4a100',
   },
}
