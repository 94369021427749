import { createContext, useContext, useReducer } from 'react'
import reducer from './reducer'

const initialState = {
   invalidFields: [],
}

const FieldValidationContext = createContext(initialState)

const FieldValidationProvider = ({ children }) => {
   const [state, dispatch] = useReducer(reducer, initialState)

   // Load Node Types
   const addInvalidField = field => {
      dispatch({
         type: 'ADD_INVALID_FIELD',
         payload: field,
      })
   }

   const removeInvalidField = field => {
      dispatch({
         type: 'REMOVE_INVALID_FIELD',
         payload: field,
      })
   }

   const clearContext = () => {
      dispatch({
         type: 'CLEAR_CONTEXT',
         payload: [],
      })
   }

   return (
      <FieldValidationContext.Provider
         value={{
            invalidFields: state.invalidFields,
            addInvalidField,
            removeInvalidField,
            clearContext,
         }}
         displayName="Invalid Fields"
      >
         {children}
      </FieldValidationContext.Provider>
   )
}

const useInvalidFields = () => {
   const context = useContext(FieldValidationContext)
   if (context === undefined) {
      throw new Error('useInvalidFields can only be used inside FieldValidationProvider')
   }
   return context
}

export { FieldValidationProvider, useInvalidFields }
