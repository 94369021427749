import makeStyles from '@mui/styles/makeStyles'
import { Box } from '@mui/material'

import { useUserPreferences } from 'core/contexts/UserPreferences'

const useStyles = makeStyles(theme => ({
   content: {
      marginLeft: ({ sidebarCollapsedState }) =>
         sidebarCollapsedState ? theme.sizing.sidebar.collapsedWidth : theme.sizing.sidebar.width,
      marginTop: theme.sizing.header.height,
      width: ({ sidebarCollapsedState }) =>
         `calc(100% - ${
            sidebarCollapsedState ? theme.sizing.sidebar.collapsedWidth : theme.sizing.sidebar.width
         }px)`,
      height: '100%',
      transition: 'all 0.3s',

      [theme.breakpoints.down('lg')]: {
         marginLeft: '0 !important',
         width: '100% !important',
      },
   },
}))

const Content = ({ children }) => {
   const { sidebarCollapsedState } = useUserPreferences()
   const classes = useStyles({ sidebarCollapsedState })

   return <Box className={classes.content}>{children}</Box>
}
export default Content
